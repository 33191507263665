/* eslint-disable consistent-return */
import React, { useCallback } from 'react'
import Button from 'components/Button'

export default function CookieButton ({ data }) {
  // Manually assign the OneTrust open-cookie-settings handler
  const handleClick = useCallback(() => {
    const openSettings = window?.OneTrust?.ToggleInfoDisplay
    if (typeof openSettings !== 'function') return
    openSettings()

    // Manually assign the close event listener;
    // Required as OneTrust functions don't assign on navved-to page
    setTimeout(() => {
      function handleClose () {
        const closeSettings = window?.OneTrust?.Close
        if (typeof closeSettings !== 'function') return
        closeSettings()
      }

      const closeButton = window.document.querySelector('#close-pc-btn-handler')
      if (closeButton) {
        closeButton.addEventListener('click', handleClose)
      } else {
        return console.error("Couldn't assign handler to close button")
      }

      const overlay = window.document.querySelector(
        '.onetrust-pc-dark-filter.ot-fade-in'
      )
      if (overlay) {
        overlay.addEventListener('click', handleClose)
      } else {
        return console.error("Couldn't assign handler to overlay")
      }
    }, 1500)
  }, [])

  return (
    <Button
      id="ot-sdk-btn"
      className="ot-sdk-show-settings"
      onClick={handleClick}
    >
      {data.label}
    </Button>
  )
}
